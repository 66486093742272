"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Group = _interopRequireDefault(require("../model/Group"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Role service.
* @module api/RoleApi
* @version 2.7.0
*/
class RoleApi {
  /**
  * Constructs a new RoleApi. 
  * @alias module:api/RoleApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the userRoleAddRequiredGroup operation.
   * @callback module:api/RoleApi~userRoleAddRequiredGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a group as a requirement to the role.
   * Needs role: user-admin
   * @param {String} roleName Name of the role.
   * @param {Number} groupId ID of the group.
   * @param {module:api/RoleApi~userRoleAddRequiredGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userRoleAddRequiredGroup(roleName, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'roleName' is set
    if (roleName === undefined || roleName === null) {
      throw new Error("Missing the required parameter 'roleName' when calling userRoleAddRequiredGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling userRoleAddRequiredGroup");
    }
    let pathParams = {
      'roleName': roleName,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/role/{roleName}/add-required-group/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userRoleRemoveRequiredGroup operation.
   * @callback module:api/RoleApi~userRoleRemoveRequiredGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a group from being a requirement from the role.
   * Needs role: user-admin
   * @param {String} roleName Name of the role.
   * @param {Number} groupId ID of the group.
   * @param {module:api/RoleApi~userRoleRemoveRequiredGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userRoleRemoveRequiredGroup(roleName, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'roleName' is set
    if (roleName === undefined || roleName === null) {
      throw new Error("Missing the required parameter 'roleName' when calling userRoleRemoveRequiredGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling userRoleRemoveRequiredGroup");
    }
    let pathParams = {
      'roleName': roleName,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/role/{roleName}/remove-required-group/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userRoleRequiredGroups operation.
   * @callback module:api/RoleApi~userRoleRequiredGroupsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all required groups of a role.
   * Needs role: user-admin
   * @param {String} roleName Role name.
   * @param {module:api/RoleApi~userRoleRequiredGroupsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  userRoleRequiredGroups(roleName, callback) {
    let postBody = null;
    // verify the required parameter 'roleName' is set
    if (roleName === undefined || roleName === null) {
      throw new Error("Missing the required parameter 'roleName' when calling userRoleRequiredGroups");
    }
    let pathParams = {
      'roleName': roleName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/role/{roleName}/required-groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = RoleApi;