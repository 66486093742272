"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Group = _interopRequireDefault(require("./Group"));
var _Player = _interopRequireDefault(require("./Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GroupApplication model module.
 * @module model/GroupApplication
 * @version 2.7.0
 */
class GroupApplication {
  /**
   * Constructs a new <code>GroupApplication</code>.
   * The player property contains only id and name.
   * @alias module:model/GroupApplication
   * @param id {Number} 
   * @param player {module:model/Player} 
   * @param group {module:model/Group} 
   * @param created {Date} 
   */
  constructor(id, player, group, created) {
    GroupApplication.initialize(this, id, player, group, created);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, player, group, created) {
    obj['id'] = id;
    obj['player'] = player;
    obj['group'] = group;
    obj['created'] = created;
  }

  /**
   * Constructs a <code>GroupApplication</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GroupApplication} obj Optional instance to populate.
   * @return {module:model/GroupApplication} The populated <code>GroupApplication</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GroupApplication();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('player')) {
        obj['player'] = _Player.default.constructFromObject(data['player']);
      }
      if (data.hasOwnProperty('group')) {
        obj['group'] = _Group.default.constructFromObject(data['group']);
      }
      if (data.hasOwnProperty('created')) {
        obj['created'] = _ApiClient.default.convertToType(data['created'], 'Date');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GroupApplication</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GroupApplication</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GroupApplication.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `player`
    if (data['player']) {
      // data not null
      _Player.default.validateJSON(data['player']);
    }
    // validate the optional field `group`
    if (data['group']) {
      // data not null
      _Group.default.validateJSON(data['group']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    return true;
  }
}
GroupApplication.RequiredProperties = ["id", "player", "group", "created"];

/**
 * @member {Number} id
 */
GroupApplication.prototype['id'] = undefined;

/**
 * @member {module:model/Player} player
 */
GroupApplication.prototype['player'] = undefined;

/**
 * @member {module:model/Group} group
 */
GroupApplication.prototype['group'] = undefined;

/**
 * @member {Date} created
 */
GroupApplication.prototype['created'] = undefined;

/**
 * Group application status.
 * @member {module:model/GroupApplication.StatusEnum} status
 */
GroupApplication.prototype['status'] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
GroupApplication['StatusEnum'] = {
  /**
   * value: "pending"
   * @const
   */
  "pending": "pending",
  /**
   * value: "accepted"
   * @const
   */
  "accepted": "accepted",
  /**
   * value: "denied"
   * @const
   */
  "denied": "denied"
};
var _default = exports.default = GroupApplication;