"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Alliance = _interopRequireDefault(require("./Alliance"));
var _Group = _interopRequireDefault(require("./Group"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Corporation model module.
 * @module model/Corporation
 * @version 2.7.0
 */
class Corporation {
  /**
   * Constructs a new <code>Corporation</code>.
   * EVE corporation.
   * @alias module:model/Corporation
   * @param id {Number} EVE corporation ID.
   * @param name {String} EVE corporation name.
   * @param ticker {String} Corporation ticker.
   */
  constructor(id, name, ticker) {
    Corporation.initialize(this, id, name, ticker);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name, ticker) {
    obj['id'] = id;
    obj['name'] = name;
    obj['ticker'] = ticker;
  }

  /**
   * Constructs a <code>Corporation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Corporation} obj Optional instance to populate.
   * @return {module:model/Corporation} The populated <code>Corporation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Corporation();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('ticker')) {
        obj['ticker'] = _ApiClient.default.convertToType(data['ticker'], 'String');
      }
      if (data.hasOwnProperty('alliance')) {
        obj['alliance'] = _Alliance.default.constructFromObject(data['alliance']);
      }
      if (data.hasOwnProperty('groups')) {
        obj['groups'] = _ApiClient.default.convertToType(data['groups'], [_Group.default]);
      }
      if (data.hasOwnProperty('trackingLastUpdate')) {
        obj['trackingLastUpdate'] = _ApiClient.default.convertToType(data['trackingLastUpdate'], 'Date');
      }
      if (data.hasOwnProperty('autoAllowlist')) {
        obj['autoAllowlist'] = _ApiClient.default.convertToType(data['autoAllowlist'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Corporation</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Corporation</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Corporation.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['ticker'] && !(typeof data['ticker'] === 'string' || data['ticker'] instanceof String)) {
      throw new Error("Expected the field `ticker` to be a primitive type in the JSON string but got " + data['ticker']);
    }
    // validate the optional field `alliance`
    if (data['alliance']) {
      // data not null
      _Alliance.default.validateJSON(data['alliance']);
    }
    if (data['groups']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['groups'])) {
        throw new Error("Expected the field `groups` to be an array in the JSON data but got " + data['groups']);
      }
      // validate the optional field `groups` (array)
      for (const item of data['groups']) {
        _Group.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
Corporation.RequiredProperties = ["id", "name", "ticker"];

/**
 * EVE corporation ID.
 * @member {Number} id
 */
Corporation.prototype['id'] = undefined;

/**
 * EVE corporation name.
 * @member {String} name
 */
Corporation.prototype['name'] = undefined;

/**
 * Corporation ticker.
 * @member {String} ticker
 */
Corporation.prototype['ticker'] = undefined;

/**
 * @member {module:model/Alliance} alliance
 */
Corporation.prototype['alliance'] = undefined;

/**
 * Groups for automatic assignment (API: not included by default).
 * @member {Array.<module:model/Group>} groups
 */
Corporation.prototype['groups'] = undefined;

/**
 * Last update of corporation member tracking data (API: not included by default).
 * @member {Date} trackingLastUpdate
 */
Corporation.prototype['trackingLastUpdate'] = undefined;

/**
 * True if this corporation was automatically placed on the allowlist of a watchlist (API: not included by default).
 * @member {Boolean} autoAllowlist
 */
Corporation.prototype['autoAllowlist'] = undefined;
var _default = exports.default = Corporation;