"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ServiceAccountData model module.
 * @module model/ServiceAccountData
 * @version 2.7.0
 */
class ServiceAccountData {
  /**
   * Constructs a new <code>ServiceAccountData</code>.
   * @alias module:model/ServiceAccountData
   * @param characterId {Number} 
   * @param username {String} 
   * @param password {String} 
   * @param email {String} 
   * @param status {module:model/ServiceAccountData.StatusEnum} 
   * @param name {String} 
   */
  constructor(characterId, username, password, email, status, name) {
    ServiceAccountData.initialize(this, characterId, username, password, email, status, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, characterId, username, password, email, status, name) {
    obj['characterId'] = characterId;
    obj['username'] = username;
    obj['password'] = password;
    obj['email'] = email;
    obj['status'] = status;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>ServiceAccountData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceAccountData} obj Optional instance to populate.
   * @return {module:model/ServiceAccountData} The populated <code>ServiceAccountData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceAccountData();
      if (data.hasOwnProperty('characterId')) {
        obj['characterId'] = _ApiClient.default.convertToType(data['characterId'], 'Number');
      }
      if (data.hasOwnProperty('username')) {
        obj['username'] = _ApiClient.default.convertToType(data['username'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ServiceAccountData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ServiceAccountData</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ServiceAccountData.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
      throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
    }
    // ensure the json data is a string
    if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
      throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
ServiceAccountData.RequiredProperties = ["characterId", "username", "password", "email", "status", "name"];

/**
 * @member {Number} characterId
 */
ServiceAccountData.prototype['characterId'] = undefined;

/**
 * @member {String} username
 */
ServiceAccountData.prototype['username'] = undefined;

/**
 * @member {String} password
 */
ServiceAccountData.prototype['password'] = undefined;

/**
 * @member {String} email
 */
ServiceAccountData.prototype['email'] = undefined;

/**
 * @member {module:model/ServiceAccountData.StatusEnum} status
 */
ServiceAccountData.prototype['status'] = undefined;

/**
 * @member {String} name
 */
ServiceAccountData.prototype['name'] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
ServiceAccountData['StatusEnum'] = {
  /**
   * value: "Pending"
   * @const
   */
  "Pending": "Pending",
  /**
   * value: "Active"
   * @const
   */
  "Active": "Active",
  /**
   * value: "Deactivated"
   * @const
   */
  "Deactivated": "Deactivated",
  /**
   * value: "Unknown"
   * @const
   */
  "Unknown": "Unknown"
};
var _default = exports.default = ServiceAccountData;