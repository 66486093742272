"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Plugin = _interopRequireDefault(require("../model/Plugin"));
var _ServiceAccountData = _interopRequireDefault(require("../model/ServiceAccountData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Service service.
* @module api/ServiceApi
* @version 2.7.0
*/
class ServiceApi {
  /**
  * Constructs a new ServiceApi. 
  * @alias module:api/ServiceApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the serviceAccounts operation.
   * @callback module:api/ServiceApi~serviceAccountsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ServiceAccountData>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns all player's service accounts for a service.
   * Needs role: user
   * @param {Number} id Service ID.
   * @param {module:api/ServiceApi~serviceAccountsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ServiceAccountData>}
   */
  serviceAccounts(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling serviceAccounts");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_ServiceAccountData.default];
    return this.apiClient.callApi('/user/service/{id}/accounts', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the serviceGet operation.
   * @callback module:api/ServiceApi~serviceGetCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Plugin} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns service.
   * Needs role: user
   * @param {Number} id ID of the service.
   * @param {module:api/ServiceApi~serviceGetCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Plugin}
   */
  serviceGet(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling serviceGet");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Plugin.default;
    return this.apiClient.callApi('/user/service/{id}/get', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the serviceRegister operation.
   * @callback module:api/ServiceApi~serviceRegisterCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ServiceAccountData} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Registers or reactivates an account with a service.
   * Needs role: group-user
   * @param {Number} id Service ID.
   * @param {Object} opts Optional parameters
   * @param {String} [email] E-mail address.
   * @param {module:api/ServiceApi~serviceRegisterCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ServiceAccountData}
   */
  serviceRegister(id, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling serviceRegister");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'email': opts['email']
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _ServiceAccountData.default;
    return this.apiClient.callApi('/user/service/{id}/register', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the serviceResetPassword operation.
   * @callback module:api/ServiceApi~serviceResetPasswordCallback
   * @param {String} error Error message, if any.
   * @param {String} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Resets password for one account.
   * Needs role: user
   * @param {Number} id Service ID.
   * @param {Number} characterId A character ID from the player's account.
   * @param {module:api/ServiceApi~serviceResetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link String}
   */
  serviceResetPassword(id, characterId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling serviceResetPassword");
    }
    // verify the required parameter 'characterId' is set
    if (characterId === undefined || characterId === null) {
      throw new Error("Missing the required parameter 'characterId' when calling serviceResetPassword");
    }
    let pathParams = {
      'id': id,
      'characterId': characterId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';
    return this.apiClient.callApi('/user/service/{id}/reset-password/{characterId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the serviceUpdateAccount operation.
   * @callback module:api/ServiceApi~serviceUpdateAccountCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update an account.
   * Needs role: user
   * @param {Number} id Service ID.
   * @param {Number} characterId A character ID from the player's account.
   * @param {module:api/ServiceApi~serviceUpdateAccountCallback} callback The callback function, accepting three arguments: error, data, response
   */
  serviceUpdateAccount(id, characterId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling serviceUpdateAccount");
    }
    // verify the required parameter 'characterId' is set
    if (characterId === undefined || characterId === null) {
      throw new Error("Missing the required parameter 'characterId' when calling serviceUpdateAccount");
    }
    let pathParams = {
      'id': id,
      'characterId': characterId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;
    return this.apiClient.callApi('/user/service/{id}/update-account/{characterId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the serviceUpdateAllAccounts operation.
   * @callback module:api/ServiceApi~serviceUpdateAllAccountsCallback
   * @param {String} error Error message, if any.
   * @param {Number} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update all service accounts of one player.
   * Needs role: user-admin, user-manager, group-admin, app-admin, user-chars, tracking or watchlist
   * @param {Number} playerId The player ID.
   * @param {module:api/ServiceApi~serviceUpdateAllAccountsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Number}
   */
  serviceUpdateAllAccounts(playerId, callback) {
    let postBody = null;
    // verify the required parameter 'playerId' is set
    if (playerId === undefined || playerId === null) {
      throw new Error("Missing the required parameter 'playerId' when calling serviceUpdateAllAccounts");
    }
    let pathParams = {
      'playerId': playerId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Number';
    return this.apiClient.callApi('/user/service/update-all-accounts/{playerId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = ServiceApi;